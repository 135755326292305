import { Injectable } from '@angular/core';
import { RouterParamsService } from '../../shared/services/router-params.service';
import { Observable } from 'rxjs';
import { Appointment } from '../models/appointment';
import { Person } from '../models/person';
import { JwtAuthService } from '../../shared/services/auth/jwt-auth.service';
import { DateService } from '../../shared/services/date.service';
import { BaseEntityService } from '../entity-management/services/base/base-entity.service';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { BasePermissionsService } from '../entity-management/services/base/base-permissions.service';

@Injectable({
  providedIn: 'root',
})
export class AppointmentsEntityService extends BaseEntityService<Appointment> {
  public lastAppointment: Appointment;

  constructor(
    httpClient: HttpClient,
    private dateService: DateService,
    private jwtAuthService: JwtAuthService,
    routerParamsService: RouterParamsService,
    basePermissionsService: BasePermissionsService,
  ) {
    super(
      httpClient,
      environment.apiUrl, { singular: 'appointment', plural: 'appointments' },
      routerParamsService,
      basePermissionsService.appointment
    );
    this.nameId = 'appointmentId';
  }

  setLastAppointment(appointment: Appointment) {
    this.lastAppointment = appointment;
  }

  initAppointment(patient: Person): Observable<Appointment> {
    const professional = this.jwtAuthService.getUser();
    const startedAt = this.dateService.getUtcDateTime();
    const body = {
      startedAt,
      endedAt: null,
      notes: null,
      patient: {
        id: patient.id,
      },
      professional: {
        id: professional.id,
      },
    };
    return this.add(body);
  }

  endAppointment(appointment): Observable<Appointment> {
    const endedAt = this.dateService.getUtcDateTime();
    const body = { ...appointment, endedAt };
    return this.update(body);
  }

  updateNotes(
    notes: string,
    appointment: Appointment,
  ): Observable<Appointment> {
    const body = { ...appointment, notes };
    return this.update(body);
  }

  updateAppointment(appointment: Appointment): Observable<Appointment> {
    let { startedAt, endedAt, startedAtTime, endedAtTime } = appointment;
    startedAt = this.dateService.format(`${ startedAt } ${ startedAtTime }`, 'dateTimeLocal', true, 'dateTimeUtc');
    endedAt = this.dateService.format(`${ endedAt } ${ endedAtTime }`, 'dateTimeLocal', true, 'dateTimeUtc');
    const body = { ...appointment, endedAt, startedAt };
    return this.update(body);
  }
}
